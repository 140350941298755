import { useState, useEffect } from 'react';
import AppContext from './AppContext';

const AppProvider = ({ children }) => {
    const [UnitValue, setUnitValue] = useState('XXX Value');
    const [UnitCode, setUnitCode] = useState(null);
    const [UnitName, setUnitName] = useState('XXX Name');

    const appSettings = {
        UnitValueName: UnitValue,
        UnitNameName: sessionStorage.getItem('unit-name'),
        UnitCodeName: sessionStorage.getItem('unit-code'),
        setUnitValue,
        setUnitName,
        setUnitCode
    };
    useEffect(() => {
        // hydrate on mount
        setUnitValue('xxx');
        setUnitCode(0);
        setUnitName('xxx');
    }, []);

    return <AppContext.Provider value={appSettings}>{children}</AppContext.Provider>;
};

export default AppProvider;
