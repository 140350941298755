// assets
import {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconSearch,
    IconUserPlus,
    IconReport,
    IconStack,
    IconBuildingStore,
    IconBook,
    IconBook2,
    IconIdBadge,
    IconDeviceMobile,
    IconDeviceMobileMessage,
    IconDeviceAnalytics,
    IconCoinRupee
} from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconSearch,
    IconReport,
    IconStack,
    IconBuildingStore,
    IconBook,
    IconBook2,
    IconIdBadge,
    IconDeviceMobileMessage,
    IconUserPlus,
    IconDeviceAnalytics,
    IconCoinRupee
};

// ==============================|| MAIN MENU ITEMS ||============================== //

const mainmenu = {
    id: 'mainmenu',
    title: 'Menu',
    type: 'group',
    children: [
        {
            id: 'mainmenu-Search',
            title: 'Search',
            type: 'item',
            url: '/search',
            icon: icons.IconSearch,
            breadcrumbs: true,
            roles: ['Admin', 'SuperUser', 'User']
        },
        {
            id: 'mainmenu-NewApplication',
            title: 'New Student',
            type: 'item',
            url: '/application',
            icon: icons.IconUserPlus,
            breadcrumbs: true,
            roles: ['Admin', 'SuperUser', 'User']
        },
        {
            id: 'mainmenu-PrintIdCard',
            title: 'ID Cards',
            type: 'item',
            url: '/idcards',
            icon: icons.IconIdBadge,
            breadcrumbs: true,
            roles: ['Admin', 'SuperUser', 'IdCardUser']
        },
        {
            id: 'mainmenu-Reports',
            title: 'Reports',
            type: 'item',
            url: '/reports',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: true,
            roles: ['Admin', 'SuperUser']
        },
        {
            id: 'mainmenu-StockRegister',
            title: 'Stock Register',
            type: 'item',
            url: '/stockregister',
            icon: icons.IconStack,
            breadcrumbs: true,
            roles: ['Admin', 'SuperUser', 'FinanceUser', 'StockUser']
        },
        {
            id: 'mainmenu-Library',
            title: 'Library',
            type: 'item',
            url: '/library',
            icon: icons.IconBook,
            breadcrumbs: true,
            roles: ['Admin', 'SuperUser', 'LibraryUser']
        },
        {
            id: 'mainmenu-cashbook',
            title: 'Cash Book',
            type: 'item',
            url: '/cashbook',
            icon: icons.IconCoinRupee,
            breadcrumbs: true,
            roles: ['Admin', 'SuperUser']
        },
        {
            id: 'mainmenu-emaheconfig',
            title: 'eMahe',
            type: 'item',
            url: '/emaheconfig',
            icon: icons.IconDeviceMobileMessage,
            breadcrumbs: true,
            roles: ['Admin', 'SuperUser', 'DigitalUser']
        }
    ]
};

export default mainmenu;
