import PropTypes from 'prop-types';
import { useContext } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';
import Chip from '@mui/material/Chip';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import BadgeSection from './BadgeSection';
import AppContext from 'components/AppContext';
// assets
import { IconMenu2 } from '@tabler/icons';
import { useAuth0 } from '@auth0/auth0-react';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const myContext = useContext(AppContext);
    const { isLoading, isAuthenticated, loginWithRedirect, user } = useAuth0();
    const roles = user['https://maheerp.com/roles'];
    const isAdmin = roles.includes('Admin');

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                {isAuthenticated == true && (
                    <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            onClick={handleLeftDrawerToggle}
                            color="inherit"
                        >
                            <IconMenu2 stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ButtonBase>
                )}
            </Box>

            {/* header search */}
            {isAuthenticated == true && <SearchSection />}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* notification & profile */}

            <Chip label={sessionStorage.getItem('unit-value')} color="primary" variant="outlined" />

            {isAuthenticated == true && isAdmin && <BadgeSection />}
            {/* {isAuthenticated == true && <NotificationSection />} */}
            {isAuthenticated == true && <ProfileSection />}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
