/* eslint-disable */
import { React, useState, useContext, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import { FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

// assets
import { Box } from '@mui/material';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../../components/AppContext';
import axios from 'axios';
import AlertBar from '../../../ui-component/AlertBar';

// styles
const IFrameWrapper = styled('iframe')(({ theme }) => ({
    height: 'calc(100vh - 210px)',
    border: '1px solid',
    borderColor: theme.palette.primary.light
}));

// =============================|| Landing  ||============================= //

const UnitSelection = () => {
    useEffect(() => {
        getUnits();
    }, []);

    const [Value, setValue] = useState('AMC');
    const myContext = useContext(AppContext);
    const navigate = useNavigate();
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [unitData, setUnitData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openmessageflag, setOpenMessageFlag] = useState(false);

    //Handle success/warning message close
    const handleMessageClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenMessageFlag(false);
    };

    async function getUnits() {
        try {
            const token = await getAccessTokenSilently();
            const response = await axios
                .get('api/units', {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                })
                .then((response) => {
                    // handle success
                    if (user.email.includes('@bpg.com')) {
                        var result = response.data.filter((item) => item.orgid === 'BAN');
                    } else if (user.email.includes('@amc.com')) {
                        var result = response.data.filter((item) => item.orgid === 'AMC');
                    } else {
                        var result = response.data;
                    }

                    setUnitData(result);
                    setIsLoading(false);
                })
                .catch((error) => {
                    // handle error
                    setOpenMessageFlag(true);
                });
        } catch (error) {
            setOpenMessageFlag(true);
        }
    }

    const handleClickSelectButton = () => {
        // setCurrentValue('VIJ');
        navigate('/landing');
    };

    const handleChangeUnitValue = (e, x) => {
        sessionStorage.setItem('unit-value', x.props.name);
        sessionStorage.setItem('unit-code', e.target.value);

        myContext.setUnitValue(x.props.name);
        myContext.setUnitCode(e.target.value);
    };

    return (
        <div style={{ backgroundColor: '#e3f2fd', borderRadius: '25px', minHeight: 'calc(100vh - 110px)', margin: '10px' }}>
            <Divider sx={{ mb: 7, mt: 0 }} />
            <Box
                variant="outlined"
                sx={{ boxShadow: 3, p: 4, m: 'auto', borderRadius: '16px', backgroundColor: 'white' }}
                width={'50%'}
                minHeight="20vh"
            >
                <Typography gutterBottom variant="h4" component="div">
                    Select Unit
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <FormControl fullWidth style={{ backgroundColor: 'white' }}>
                    <InputLabel id="unit-select-label">Unit</InputLabel>
                    <Select
                        labelId="unit-select-label"
                        id="unit-select"
                        label="Unit"
                        defaultValue={window.sessionStorage.getItem('unit-code')}
                        onChange={handleChangeUnitValue}
                    >
                        {unitData.map((data) => {
                            return (
                                <MenuItem key={data.id} value={data.id} name={data.name}>
                                    {data.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <Box textAlign="center" sx={{ paddingTop: 2, backgroundColor: 'white' }}>
                    <Button variant="contained" centerRipple onClick={handleClickSelectButton} disabled={isLoading}>
                        Select
                    </Button>
                </Box>
            </Box>
            <AlertBar
                open={openmessageflag}
                message="Please contact administrator"
                severity="error"
                closeHandler={handleMessageClose}
            ></AlertBar>
        </div>
    );
};

export default withAuthenticationRequired(UnitSelection, {
    onRedirecting: () => <ReactLoading type="Spin" color="#fff" />
});
