/* eslint-disable */
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import ReactLoading from 'react-loading';
import { useAuth0 } from '@auth0/auth0-react';
//import AppContext from './components/AppContext';
import AppProvider from './components/AppProvider.js';

// ==============================|| APP ||============================== //

const App = () => {
    const { isLoading } = useAuth0();

    if (isLoading) {
        return <ReactLoading type="Spin" color="#fff" />;
    }
    const customization = useSelector((state) => state.customization);

    // const [UnitValue, setUnitValue] = useState('XXX');
    // const [UnitCode, setUnitCode] = useState(0);
    // const [UnitName, setUnitName] = useState('XXX Name');

    // const appSettings = {
    //     UnitValueName: UnitValue,
    //     UnitNameName: UnitName,
    //     UnitCodeName: UnitCode,
    //     setUnitValue,
    //     setUnitName,
    //     setUnitCode
    // };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <AppProvider>
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </AppProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
