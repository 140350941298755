import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';

//Third party
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const roles = user['https://maheerp.com/roles'];
    const isAdmin = roles.includes('Admin');

    var pathValue;
    if (isAdmin) {
        pathValue = config.adminPath;
    } else {
        pathValue = config.defaultPath;
    }

    return (
        <>
            <ButtonBase disableRipple component={Link} to={pathValue}>
                <Logo />
            </ButtonBase>
        </>
    );
};

export default LogoSection;
