import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Badge1 from '@mui/icons-material/BadgeRounded';
import Discount from '@mui/icons-material/NewReleasesRounded';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import axios from 'axios';
import AppContext from '../../../../components/AppContext';
import BadgeNewReg from '@mui/icons-material/GroupAddRounded';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px'
    }
}));

export default function CustomizedBadges() {
    //Context
    const myContext = useContext(AppContext);
    //Auth0
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [pendingidcard, setPendingIdCard] = useState(0);
    const [pendingexemption, setPendingExemption] = useState(0);
    const [newregistration, setNewRegistration] = useState(0);

    const roles = user['https://maheerp.com/roles'];
    const isAdmin = roles.includes('Admin');

    useEffect(() => {
        if (isAdmin) {
            getBadgeCount();
        }
    }, []);

    async function getBadgeCount() {
        const token = await getAccessTokenSilently();
        axios
            .get('api/admin/badgecount', {
                params: {
                    unitId: myContext.UnitCodeName
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response) => {
                setPendingIdCard(response.data[0].pendingidcard);
                setPendingExemption(response.data[0].pendingexemption);
                setNewRegistration(response.data[0].newregistrations);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            <IconButton
                aria-label="idcards"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                title={newregistration + ' - New Registrations'}
                sx={{ mr: 1 }}
            >
                <StyledBadge badgeContent={newregistration} max={10000} color="secondary">
                    <BadgeNewReg />
                </StyledBadge>
            </IconButton>
            <IconButton
                aria-label="idcards"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                title={pendingidcard + ' - Pending ID Cards'}
                sx={{ mr: 1 }}
            >
                <StyledBadge badgeContent={pendingidcard} max={10000} color="secondary">
                    <Badge1 />
                </StyledBadge>
            </IconButton>
            <IconButton
                aria-label="exemption"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                title={pendingexemption + ' - Pending Exemption Approvals'}
                sx={{ mr: 2 }}
            >
                <StyledBadge badgeContent={pendingexemption} max={10000} color="secondary">
                    <Discount />
                </StyledBadge>
            </IconButton>
        </>
    );
}
