// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    var isAdmin = false;
    if (isAuthenticated) {
        const roles = user['https://maheerp.com/roles'];
        isAdmin = roles.includes('Admin');
    } else {
        isAdmin = false;
    }

    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                if (item.title == 'Admin') {
                    if (isAdmin) {
                        return <NavGroup key={item.id} item={item} />;
                    } else {
                        return <Typography align="center"></Typography>;
                    }
                } else {
                    return <NavGroup key={item.id} item={item} />;
                }
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error 3
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
