import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import mainmenu from './mainmenu';
import adminmenu from './adminmenu';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    // items: [dashboard, pages, utilities, other]
    items: [mainmenu, adminmenu]
};

export default menuItems;
