import { Outlet } from 'react-router-dom';

// project imports
import Customization from '../Customization';
import ProfileSection from '../MainLayout/Header/ProfileSection';
import LogoSection from '../MainLayout/LogoSection';
import { Avatar, Box, ButtonBase } from '@mui/material';
import ReactLoading from 'react-loading';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import UnitSelection from 'views/pages/unitselection';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

    return (
        <>
            <div style={{ display: 'flex' }}>
                <div style={{ flexGrow: '1' }}>
                    <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                        <LogoSection />
                    </Box>
                </div>
                <div>
                    <ProfileSection />
                </div>
            </div>
            <br />
            <br />
            <UnitSelection></UnitSelection>
            <Outlet />
            {/* <Customization /> */}
        </>
    );
};

export default withAuthenticationRequired(MinimalLayout, {
    onRedirecting: () => <ReactLoading type="Spin" color="#fff" />
});
