/* eslint-disable */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    var roles = [];
    if (isAuthenticated) {
        roles = user['https://maheerp.com/roles'];
    } else {
        roles = [];
    }
    const theme = useTheme();
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    // menu list collapse & items
    const items = item.children?.map((menu) => {
        let isAuthorized = isAuthorizedUser(roles, menu.roles);

        switch (menu.type) {
            case 'collapse':
                if (isAuthorized) {
                    return <NavCollapse key={menu.id} menu={menu} level={1} />;
                } else {
                    return <Typography align="center"></Typography>;
                }
            case 'item':
                if (isAuthorized) {
                    return <NavItem key={menu.id} item={menu} level={1} />;
                } else {
                    return <Typography align="center"></Typography>;
                }
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error 1
                    </Typography>
                );
        }
    });

    function isAuthorizedUser(arr1, arr2) {
        return arr1.some((item) => arr2.includes(item));
    }

    return (
        <>
            <List
                subheader={
                    leftDrawerOpened &&
                    item.title && (
                        <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                            {item.title}
                            {item.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {item.caption}
                                </Typography>
                            )}
                        </Typography>
                    )
                }
            >
                {/* {console.log('NavGroup --> ' + item.title)} */}
                {items}
            </List>

            {/* group divider */}
            {leftDrawerOpened && <Divider sx={{ mt: 0.25, mb: 1.25 }} />}
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
