// assets
import {
    IconDiscountCheck,
    IconUserCheck,
    IconSettings,
    IconCheck,
    IconFilePencil,
    IconUpload,
    IconClock,
    IconDeviceMobile
} from '@tabler/icons';

// constant
const icons = {
    IconUserCheck,
    IconSettings,
    IconCheck,
    IconUpload,
    IconClock,
    IconDeviceMobile,
    IconFilePencil,
    IconDiscountCheck
};

// ==============================|| MAIN MENU ITEMS ||============================== //

const adminmenu = {
    id: 'adminmenu',
    title: 'Admin',
    type: 'group',
    roles: ['Admin'],
    children: [
        {
            id: 'adminmenu-Approval',
            title: 'Exemption Approval',
            type: 'item',
            url: '/approval',
            icon: icons.IconDiscountCheck,
            breadcrumbs: true,
            roles: ['Admin']
        },
        {
            id: 'adminmenu-Adjustment',
            title: 'Fee Correction',
            type: 'item',
            url: '/adjustment',
            icon: icons.IconFilePencil,
            breadcrumbs: false,
            roles: ['Admin']
        },
        {
            id: 'adminmenu-Attendance',
            title: 'Attendance',
            type: 'item',
            url: '/attendance',
            icon: icons.IconClock,
            breadcrumbs: true,
            roles: ['Admin', 'SuperUser', 'User']
        },
        {
            id: 'adminmenu-Configuration',
            title: 'Configuration',
            type: 'collapse',
            icon: icons.IconSettings,
            roles: ['Admin'],
            children: [
                {
                    id: 'adminmenu-CourseConfig',
                    title: 'Course',
                    type: 'item',
                    url: '/courseconfig',
                    breadcrumbs: true,
                    roles: ['Admin']
                },
                {
                    id: 'adminmenu-RouteConfig',
                    title: 'Route',
                    type: 'item',
                    url: '/routeconfig',
                    breadcrumbs: true,
                    roles: ['Admin']
                },
                {
                    id: 'adminmenu-FeeConfig',
                    title: 'Fee',
                    type: 'item',
                    url: '/feeconfig',
                    breadcrumbs: true,
                    roles: ['Admin']
                }
            ]
        }
    ]
};

export default adminmenu;
