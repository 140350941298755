import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const LoginPage = Loadable(lazy(() => import('views/pages/authentication/login')));
const LandingPage = Loadable(lazy(() => import('views/pages/landing')));
const SearchPage = Loadable(lazy(() => import('views/pages/search')));
const ApplicationPage = Loadable(lazy(() => import('views/pages/application')));
const ReportPage = Loadable(lazy(() => import('views/pages/reports')));
const IdCards = Loadable(lazy(() => import('views/pages/idcards')));
const LibraryPage = Loadable(lazy(() => import('views/pages/library')));
const StockPage = Loadable(lazy(() => import('views/pages/stockregister')));
const CashBookPage = Loadable(lazy(() => import('views/pages/cashbook')));

//Config
const CourseconfigPage = Loadable(lazy(() => import('views/pages/config/courseconfig')));
const RouteconfigPage = Loadable(lazy(() => import('views/pages/config/routeconfig')));
const ApprovalPage = Loadable(lazy(() => import('views/pages/approval')));
const AdjustmentPage = Loadable(lazy(() => import('views/pages/adjustment')));

//emahe
const UserEnrollmentPage = Loadable(lazy(() => import('views/pages/userenrollment')));
const EmaheVideos = Loadable(lazy(() => import('views/pages/emahevideos')));
const EmaheConfig = Loadable(lazy(() => import('views/pages/emaheconfig')));
const EmaheMcq = Loadable(lazy(() => import('views/pages/emahemcq')));
const EmaheCalendar = Loadable(lazy(() => import('views/pages/emahecalendar')));
const EmaheLeaves = Loadable(lazy(() => import('views/pages/emaheleaves')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'dashboard',
            children: [
                {
                    path: '',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'login',
            children: [
                {
                    path: '',
                    element: <LoginPage />
                }
            ]
        },
        {
            path: 'landing',
            children: [
                {
                    path: '',
                    element: <LandingPage />
                }
            ]
        },
        {
            path: 'search',
            children: [
                {
                    path: '',
                    element: <SearchPage />
                }
            ]
        },
        {
            path: 'application',
            children: [
                {
                    path: '',
                    element: <ApplicationPage />
                }
            ]
        },
        {
            path: 'idcards',
            children: [
                {
                    path: '',
                    element: <IdCards />
                }
            ]
        },
        {
            path: 'reports',
            children: [
                {
                    path: '',
                    element: <ReportPage />
                }
            ]
        },
        {
            path: 'library',
            children: [
                {
                    path: '',
                    element: <LibraryPage />
                }
            ]
        },
        {
            path: 'cashbook',
            children: [
                {
                    path: '',
                    element: <CashBookPage />
                }
            ]
        },
        {
            path: 'emaheconfig',
            children: [
                {
                    path: '',
                    element: <EmaheConfig />
                }
            ]
        },
        {
            path: 'stockregister',
            children: [
                {
                    path: '',
                    element: <StockPage />
                }
            ]
        },
        {
            path: 'approval',
            children: [
                {
                    path: '',
                    element: <ApprovalPage />
                }
            ]
        },
        {
            path: 'adjustment',
            children: [
                {
                    path: '',
                    element: <AdjustmentPage />
                }
            ]
        },
        {
            path: 'courseconfig',
            children: [
                {
                    path: '',
                    element: <CourseconfigPage />
                }
            ]
        },
        {
            path: 'routeconfig',
            children: [
                {
                    path: '',
                    element: <RouteconfigPage />
                }
            ]
        },
        {
            path: 'userenrollment',
            children: [
                {
                    path: '',
                    element: <UserEnrollmentPage />
                }
            ]
        },
        {
            path: 'emahevideos',
            children: [
                {
                    path: '',
                    element: <EmaheVideos />
                }
            ]
        },
        {
            path: 'emahemcq',
            children: [
                {
                    path: '',
                    element: <EmaheMcq />
                }
            ]
        },
        {
            path: 'emahecalendar',
            children: [
                {
                    path: '',
                    element: <EmaheCalendar />
                }
            ]
        },
        {
            path: 'emaheleaves',
            children: [
                {
                    path: '',
                    element: <EmaheLeaves />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: '/x',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
